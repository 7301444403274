import React from 'react'

import { BsGithub, BsLinkedin, BsMailbox } from 'react-icons/bs'
import { ImProfile } from 'react-icons/im'


const SocialMedia = () => {
    return (
        <div className="app__social">
            <div>
                <a href="https://github.com/dawidglap/" rel="noreferrer" target="_blank"><BsGithub></BsGithub> </a>
            </div>
            <div>
                <a href="https://www.linkedin.com/in/dawid-glapiak/" rel="noreferrer" target="_blank">
                    <BsLinkedin></BsLinkedin>

                </a>
            </div>
            <div>
                <a href="#contact"> <BsMailbox></BsMailbox> </a>
            </div>
            <div className="cv">
                <a href="https://drive.google.com/uc?export=download&id=1YyrIr2YjRGEDNd5loFVdkdZ-yYhHNzxS" rel="noreferrer" > <ImProfile ></ImProfile > </a>
            </div>
        </div>
    )
}

export default SocialMedia

// https://drive.google.com/uc?export=download&id=1YyrIr2YjRGEDNd5loFVdkdZ-yYhHNzxS